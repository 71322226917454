<template>
	<div>
		<v-textarea
			:id="id"
			ref="input-text-area"
			:label="label"
			:loading="loading"
			:disabled="disabled"
			:hide-details="hideDetails"
			:readonly="readonly"
			outlined
			:rows="rows"
			:rules="rules"
			class="pt-0"
			:class="{ 'mt-3': !dense, ...customClass }"
			v-on:paste="(e) => onPaste(e)"
			v-on:keypress="(e) => manageLimit(e)"
			v-model="textareainput"
			:placeholder="placeholder"
			:counter="counter"
		></v-textarea>
		<div class="text-right">{{ textareainput ? textareainput.length : 0 }}/{{ wordLimit }}</div>
	</div>
</template>
<script>
export default {
	name: "textarea-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		wordLimit: {
			type: Number,
			default: 0,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		rows: {
			type: Number,
			default: 3,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		counter: {
			type: String,
			default: "100",
		},
		customClass: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			textareainput: null,
		};
	},
	watch: {
		value() {
			this.textareainput = this.value;
		},
		textareainput() {
			this.$emit("input", this.textareainput);
		},
	},
	methods: {
		onPaste(e) {
			let coppied = e.clipboardData.getData("Text");
			let preVal = this.textareainput;
			let finalval = String(preVal) + String(coppied);
			if (Number(finalval.length) > this.wordLimit - 1) {
				let trimValue = finalval.substring(0, this.wordLimit);
				this.textareainput = trimValue;
				e.preventDefault();
			}
		},
		manageLimit(e) {
			if (this.textareainput && this.textareainput.length > this.wordLimit - 1) {
				e.preventDefault();
			}
		},
	},
	mounted() {
		this.textareainput = this.value;
	},
};
</script>
